<template>
    <v-form ref="form" id="form" style="width: 100%">
        <v-row>
            <v-col cols="12">
                <v-btn class="ma-1" large solo depressed color="secondary" @click="show_import_dialog">
                    <v-icon class="mr-2">cloud_upload</v-icon>
                    <span class="subtitle-2">Importar participantes</span>
                </v-btn>
                <v-btn class="ma-1" large solo depressed color="green" dark @click="download_format">
                    <v-icon class="mr-2">get_app</v-icon>
                    <span class="subtitle-2">Descargar formato</span>
                </v-btn>
            </v-col>
            <v-col cols="12">
                <DashCard title="Consideraciones">
                    <span class="subtitle-1">
                        <ul>
                            <li><b>No se podrán cargar cursos externos</b> de participantes que no esten registrados en el sistema. Tome la precaución de que los
                            participantes en su planilla ya tengan una cuenta de acceso creada.</li>
                        </ul>
                    </span>
                    <div class="mt-2 text-right">
                        <v-btn depressed color="primary" class="subtitle-2" large @click="upload">
                            Guardar cambios
                        </v-btn>
                        <v-btn depressed color="primary" class="subtitle-2 ml-2" large exact :to="{ name: 'dashboard_groups_list' }">
                            Descartar
                        </v-btn>
                    </div>
                </DashCard>
            </v-col>
            <v-col cols="12" md="12" v-if="!caducado">
                <DashCard :title="`Participantes (${item.participantes.length})`" title-icon="group" body-class="pa-0">
                    <v-divider />
                    <CursoExternoMasEditor v-model="item.participantes" ref="participantes"/>
                </DashCard>
            </v-col>
        </v-row>
        <DownloadDialog ref="down_dialog" />
        <DialogAddParticipante ref="add_dialog" :personas="list_participantes" @success="add_to_list" />
        <DialogImportCursosExternos ref="import_dialog" @success="add_to_list" />
        <Message ref="success" @onClose="$router.push({ name: 'dashboard_groups_list' })" />
        <Message ref="error" />
    </v-form>
</template>

<script>
import DialogImportCursosExternos from '@/educap/dialogs/DialogImportCursosExternos'
import DialogAddParticipante from '@/educap/dialogs/DialogAddParticipante'
import DatePickerText from '@/shared/components/DatePickerText'
import CursoListEditor from '@/educap/components/CursoListEditor'
import CursoExternoMasEditor from '@/educap/components/CursoExternoMasEditor'
import IncidenteSelector from '@/educap/components/IncidenteSelector'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import DownloadDialog from '@/shared/components/DownloadDialog'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        CursoListEditor,
        DownloadDialog,
        DialogAddParticipante,
        DialogImportCursosExternos,
        CursoExternoMasEditor,
        IncidenteSelector,
        DatePickerText,
        DashCard,
        Message
    },
    data: () => ({
        item: {
            participantes: [],
            cursos: []
        },
        headers: [
            { value: "rut", text: "R.U.T" },
            { value: "first_name", text: "Nombres" },
            { value: "last_name", text: "Apellidos" },
            { value: "empresa", text: "Empresa" },
            { value: "gerencia", text: "Gerencia" },
            { value: "actions", text: "", sortable: false }
        ],
        list_participantes: [],
        rules: {
            required: valid_required
        }
    }),
    computed: {
        is_super() {
            return this.$parent.$parent.isin('supers');
        },
        caducado() {
            return this.$route.params.caducado;
        }
    },
    methods: {
        download_format() {
            this.$refs.down_dialog.show("edu/grupos/formato/");
        },
        show_add_dialog() {
            this.$refs.add_dialog.show();
        },
        show_import_dialog() {
            this.$refs.import_dialog.show();
        },
        add_to_list(items) {
            this.item.participantes = this.item.participantes.concat(items);
            this.$forceUpdate();
        },
        remove(item) {
            this.item.participantes.splice(this.item.participantes.indexOf(item), 1);
            this.item.participantes = [...this.item.participantes];
        },
        upload() {
            const edit = this.$route.meta.edit_mode;
            let item = { ...this.item };

            if(!this.$refs.form.validate()) {
                this.error({ data: { detail: messages.texts["error_check_fields"] } });
                return;
            }

            if(this.item.participantes.length < 1) {
                this.error({ data: { detail: "Debe haber al menos un participante." } });
                return;
            }

            if(this.item.cursos.length < 1) {
                this.error({ data: { detail: "Debe haber al menos un curso asignado." } });
                return;
            }

            if(this.item.participantes.filter(x => !!x.gerencia) < 1) {
                this.error({ data: { detail: "Todos los participantes deben tener datos de Gerencia perteneciente" } });
                return;
            }

            if(this.item.participantes.filter(x => !!x.empresa) < 1) {
                this.error({ data: { detail: "Todos los participantes deben tener datos de Empresa perteneciente" } });
                return;
            }
            
            if(!edit) {     
                console.log("!edit");   
                axiosHelper.post('edu/grupos/', item)
                        .then(response => this.success(response))
                        .catch(response => this.error(response));
            } else {
                axiosHelper.put('edu/grupos/' + this.$route.params.id + '/', this.item)
                        .then(response => this.success(response))
                        .catch(response => this.error(response));
            }
        },
        success(response) {
            const edit = this.$route.meta.edit_mode;
            this.$refs.success.show(messages.titles['success_generico'], messages.texts[edit ? 'success_upload_edit' : 'success_upload_new'], "success");
        },
        error(response) {
            var text = !!response.data ? response.data.detail : messages.texts['error_desconocido'];
            this.$refs.error.show(messages.titles['error_generico'], text, "error");
        }
    },
    mounted() {
        const title = "Importar cursos externos";
        axiosHelper.get('edu/importar-cursos')
            .then(response => {
                // Adaptar la estructura de datos
                const participantes = response.data.map(participante => {
                    return {
                        usuario_id: participante.usuario_id,
                        cursos: participante.cursos.map(curso => ({
                            nombre_curso: curso.nombre_curso,
                            fecha_vencimiento: curso.fecha_vencimiento
                        }))
                    };
                });
                this.list_participantes = participantes;
            });
        // this.$parent.$parent.set_title(title, "group_add");
    }
}
</script>
