<template>
    <v-dialog v-model="visible" max-width="950px" transition="fade-transition">
        <WindowCard title="Seleccionar incidente ambiental" title-icon="style" :actions="true" actions-align="right">
            <BuscadorTabla ref="buscador" v-model="busqueda" :target="tabla_items" :types="busqueda_settings" />
            <v-form id="form" ref="form">
                <v-card outlined flat>
                    <v-data-table dense :headers="headers" :items="tabla_items" :search="busqueda" :custom-filter="filtro" :items-per-page.sync="pagination.item_page" :page="pagination.page" hide-default-footer>
                        <template v-slot:item.id="{ item }">
                            <v-radio-group hide-details class="pa-0 ma-0" v-model="option">
                                <v-radio class="ma-0 pa-0" :label="item.id.toString()" :value="item" />
                            </v-radio-group>
                        </template>
                        <template v-slot:item.fecha_incidente="{ item }">
                             {{ item.fecha_incidente | moment("L") }}
                        </template>
                        <template v-slot:item.fecha_incidente="{ item }">
                             {{ item.fecha_incidente | moment("L") }}
                        </template>
                        <template v-slot:footer>
                            <v-divider />
                            <v-pagination v-model="pagination.page" :length="pagination_length" :total-visible="10" />
                        </template>
                    </v-data-table>
                </v-card>
                <v-card outlined flat v-if="!!option" class="mt-4">
                    <v-card-text>
                        <span class="subtitle-1 d-block font-weight-bold">Cod. Incidente: [{{ option.cod_incidente }}]</span>
                        <span class="subtitle-1 d-block text--primary">{{ option.titulo }}</span>
                        <v-chip color="indigo" dark><v-icon left>account_circle</v-icon>{{ option.persona_autor }}</v-chip>
                        <span class="subtitle-2 d-block text--primary">Fecha de Incidente: {{ option.fecha_incidente | moment("L") }}</span>
                    </v-card-text>
                </v-card>
            </v-form>
            <template slot="actions">
                <v-btn depressed color="primary" class="subtitle-2" large @click="save">
                    <v-icon left>done</v-icon>
                    Aceptar
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2" large @click="cancel">
                    <v-icon left>close</v-icon>
                    Cancelar
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import PersonaListItem from '@/dash/components/PersonaListItem'
import Message from '@/shared/components/Message'
import BuscadorTabla from '@/shared/components/BuscadorTabla'
import { axiosHelper, messages } from '@/helpers'

export default {
    props: ['value', 'datos'],
    components: {
        WindowCard,
        PersonaListItem,
        Message,
        BuscadorTabla
    },
    data: () => ({
        headers: [
            { value: "id", text: "ID", width: '100px' },
            { value: "cod_incidente", text: "Cod. Incid.", width: '120px' },
            { value: "titulo", text: "Titulo" },
            { value: "fecha_incidente", text: "Fecha" },
            { value: "persona_autor", text: "Autor Registro", width: '150px' }
        ],
        pagination: {
            page: 1,
            item_page: 8,
        },
        busqueda_settings: [
            { value: null, text: "Todo" },
            { value: "id", text: "ID" },
            { value: "cod_incidente", text: "Código Incidente" },
            { value: "fecha_incidente", text: "Fecha" },
            { value: "titulo", text: "Título" },
            { value: "persona_autor", text: "Autor Registro" }
        ],
        busqueda: null,
        option: null,
        visible: false
    }),
    computed: {
        tabla_items() {
            if(!this.$refs.buscador) return this.datos;
            return this.datos.filter(x => this.$refs.buscador.filter_estado(x));
        },
        pagination_length() {
            if(!this.tabla_items) return 1;
            if(this.tabla_items.length < 1) return 1;
            
            return Math.round(this.tabla_items.length / this.pagination.item_page);
        }
    },
    methods: {
        filtro(value, search, item) {
            return this.$refs.buscador.filtro(value, search, item);
        },
        show() {
            this.option = null;
            this.visible = true;
        },
        save() {
            if (!this.option) {
                this.$refs.error.show(messages.titles["error_generico"], 'Debe seleccionar un incidente', "error");
                return;
            }

            this.$emit("save", this.option);
            this.visible = false;
        },
        cancel() {
            this.visible = false;
        }
    }
}
</script>