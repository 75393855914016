<template>
    <v-data-table :headers="headers" :items="value" :mobile-breakpoint="0" hide-default-footer :items-per-page="-1">
        <template v-slot:item.usuario_id="{ item }">
            <v-edit-dialog :return-value.sync="item.usuario_id">
                <template v-slot:default>
                    <template v-if="!!item.usuario_id">{{ item.usuario_id }}</template>
                    <template v-else><v-icon color="red" left>error_outline</v-icon>¡Sin R.U.T!</template>
                </template>
                <template v-slot:input>
                    <v-text-field class="my-2" hide-details single-line solo outlined flat dense v-model="item.usuario_id" label="R.U.T" />
                </template>
            </v-edit-dialog>
        </template>
        <template v-slot:item.cursos="{ item }">
            <ul>
                <li v-for="(curso, index) in item.cursos" :key="index">
                    {{ curso.nombre_curso }} - {{ curso.fecha_vencimiento }}
                </li>
            </ul>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn icon @click="remove(item)">
                <v-icon>close</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import Selector from '@/dash/components/Selector'

export default {
    props: ['value'],
    components: {
        Selector
    },
    data: () => ({
        headers: [
            { value: "usuario_id", text: "R.U.T", sortable: false, divider: true, width: '130px' },
            { value: "cursos", text: "Cursos externos", sortable: false },
            { value: "actions", text: "", sortable: false, width: '32px' }
        ]
    }),
    methods: {
        remove(item) {
            this.value.splice(this.value.indexOf(item), 1);
            this.value = [...this.value];
        }
    }
}
</script>
