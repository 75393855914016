<template>
    <v-card outline flat>
        <v-data-table dense :disabled="disabled" :headers="header" :items="value_list" :hide-default-footer="value_list.length < 3" hide-default-header :items-per-page="value_list.length > 3 ? 3 : -1" :mobile-breakpoint="0">
            <template v-slot:item.actions="{ item }">
                <v-btn v-if="!disabled" icon @click="remove(item)">
                    <v-icon>close</v-icon>
                </v-btn>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-divider />
                <div class="pa-2">
                    <v-autocomplete
                        clearable solo outlined flat dense hide-details 
                        prepend-inner-icon="class" label="Seleccione un curso y presione 'Añadir'"
                        v-model="adding" 
                        :items="items" 
                        :item-text="(i) => `${i.nombre}`"
                        return-object>
                        <template v-slot:append-outer>
                            <v-btn :disabled="!adding" color="primary" depressed class="subtitle-2 my-n1" @click="add_to_list()">
                                <v-icon left>add</v-icon> Añadir
                            </v-btn>
                        </template>
                    </v-autocomplete>
                </div>
            </template>
        </v-data-table>
        <Message ref="error" />
    </v-card>
</template>

<script>
import { messages } from '@/helpers'
import Message from '@/shared/components/Message'

export default {
    props: ['value', 'items', 'disabled'],
    components: {
        Message
    },
    data: () => ({
        header: [
            { text: "Nombre", value: "nombre" },
            { text: "", value: "actions", sortable: false, width: '32px' }
        ],
        adding: null
    }),
    computed: {
        value_list: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        remove (item) {
            this.value_list.splice(this.value.indexOf(item), 1);
            this.value_list = [...this.value_list];
        },
        add_to_list() {
            if(this.value_list.some(x => x.id == this.adding.id)) {
                this.$refs.error.show(messages.titles["error_generico"], "Este curso ya ha sido añadido.", "error");
                return;
            }

            this.value_list.push(Object.assign({} , this.adding));
            this.adding = null;
            this.$forceUpdate();
        }
    }
}
</script>