<template>
    <v-dialog v-model="visible" max-width="800px" transition="fade-transition">
        <WindowCard title="Añadir participantes registrados" title-icon="style" :actions="true" actions-align="right">
            <v-form id="form" ref="form">
                <v-row no-gutters>
                    <v-col cols="12" md="6" class="pa-1">
                        <SelectableList v-model="items" :items="personas" dense searchable>
                            <template v-slot:item-content="{ item }">
                                <span class="d-block font-weight-bold">{{ item.first_name + ' ' + item.last_name }}</span>
                                <span class="d-block">{{ item.empresa.nombre }}, {{ item.gerencia.nombre }}</span>
                            </template>
                        </SelectableList>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-1">
                        <v-card outlined flat>
                            <v-data-table dense :items="items" :headers="selected_headers" :items-per-page="-1" hide-default-footer>
                                <template v-slot:item.first_name="{ item }">
                                    {{ item.first_name }} {{ item.last_name }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-btn icon @click="remove(item)">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn depressed color="primary" class="subtitle-2" large @click="save">
                    <v-icon left>done</v-icon>
                    Aceptar
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2" large @click="cancel">
                    <v-icon left>close</v-icon>
                    Cancelar
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import SelectableList from '@/dash/components/SelectableList'
import Message from '@/shared/components/Message'
import { messages } from '@/helpers'

export default {
    props: ['value', 'personas'],
    components: {
        WindowCard,
        SelectableList,
        Message
    },
    data: () => ({
        search: null,
        visible: false,
        selected_headers: [
            { value: "rut", text: "RUT", sortable: false },
            { value: "first_name", text: "Nombre completo", sortable: false },
            { value: "actions", text: "", sortable: false }
        ],
        items: []
    }),
    methods: {
        show() {
            this.visible = true;
            this.items = [];
        },
        remove(item) {
            this.items.splice(this.items.indexOf(item), 1);
            this.items = [...this.items];
        },
        save() {
            if(!this.$refs.form.validate()) {
                this.$refs.error.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }

            if (this.items.length < 1) {
                this.$refs.error.show(messages.titles["error_generico"], 'Debe seleccionar al menos un participante', "error");
                return;
            }

            this.$emit("success", this.items);
            this.visible = false;
        },
        cancel() {
            this.visible = false;
        }
    }
}
</script>