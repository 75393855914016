<template>
    <v-card flat outlined tile>
        <v-card-text class="pa-2">
            <v-row no-gutters justify="center">
                <v-col class="flex-grow-1 flex-shrink-0">
                    <template v-if="!value">
                        <span class="subtitle-2 d-block py-2">Sin incidente asociado</span>
                    </template>
                    <template v-else>
                        <span class="subtitle-2 d-block">Cod. Incidente: {{ value.cod_incidente }}</span>
                        <span class="subtitle-2 d-block text--primary py-2">{{ value.titulo }}</span>
                    </template>
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-1" align="right">
                    <v-btn @click="show" depressed dark color="primary" class="subtitle-2">Seleccionar</v-btn>
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-1" align="right">
                    <v-btn icon @click="clear" depressed class="subtitle-2"><v-icon>close</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <DialogSelectIncidente ref="select_dialog" :datos="datos" @save="apply" />
    </v-card>
</template>

<script>
import DialogSelectIncidente from '@/educap/dialogs/DialogSelectIncidente'

export default {
    props: ['value', 'datos'],
    components: {
        DialogSelectIncidente
    },
    methods: {
        show() {
            this.$refs.select_dialog.show();
        },
        apply(incidente) {
            this.$emit('input', incidente);
        },
        clear() {
            this.$emit('input', null);
        }
    }
}
</script>